.footer {
  height: 100%;
  color: #fff;
  background-color: #242b3a;

  &__container {
    position: relative;
    background: url("../../../images/Footer/Gradient.png") no-repeat;
    background-size: 100% auto;
    background-position: 100% 100%;
    padding: 64px 0 66px 80px;

    @media screen and (max-width: 944px) {
      background: url("../../../images/Footer/Gradient2.png") no-repeat;
      background-size: 100% auto;
      background-position: 100% 100%;
    }

    @media screen and (max-width: 450px) {
      background: url("../../../images/Footer/Gradient3.png") no-repeat;
      background-size: 120% auto;
      background-position: 100% 100%;
    }

    @media screen and (max-width: 450px) {
      padding: 48px 0 52px 28px;
    }

    @media screen and (min-width: 1366px) {
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 90px;
        background: linear-gradient(
          to right,
          #242b3a 0%,
          rgba(36, 43, 58, 0) 100%
        );
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
        background: linear-gradient(
          to left,
          #242b3a 0%,
          rgba(36, 43, 58, 0) 100%
        );
      }

      max-width: 1366px;
      margin: 0 auto;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin: 0 0 24px;

    @media screen and (max-width: 450px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
    }
  }

  &__mailBlock {
    display: flex;
    align-items: flex-start;
    margin: 0 0 12px;
  }

  &__phoneBlock {
    display: flex;
    align-items: flex-start;
    margin: 0 0 138px;

    @media screen and (max-width: 944px) {
      margin: 0 0 56px;
    }
  }

  &__mailText {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #b8bcc7;
    margin: 0 0 0 8px;
    transition: 0.4s;

    &:hover {
      color: white;
    }

    @media screen and (max-width: 450px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__phoneText {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #b8bcc7;
    margin: 0 0 0 8px;

    @media screen and (max-width: 450px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__copyHeaderBlock {
    display: flex;
    align-items: flex-start;
    align-items: center;

    @media screen and (max-width: 944px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__logoLink {
    display: flex;
    margin: 0 190px 0 0;

    @media screen and (min-width: 1366px) {
      margin: 0 223px 0 0;
    }

    @media screen and (max-width: 1180px) {
      margin: 0 70px 0 0;
    }

    @media screen and (max-width: 980px) {
      margin: 0 40px 0 0;
    }

    @media screen and (max-width: 944px) {
      margin: 0 0 68px;
    }
  }

  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #ffffff;
    border: 1px solid transparent;
    transition: 0.4s;
    padding: 8px 8px;

    &:focus,
    &:hover {
      border: 1px solid white;
      border-radius: 4px;
      outline: none;
      transition: 0.4s;
      padding: 8px 8px;
      transform: scale(0.99);
    }

    br {
      display: none;
    }
  }

  ul {
    display: flex;

    @media screen and (max-width: 944px) {
      flex-direction: column;
    }

    li {
      transition: 0.4s;
      margin: 0 34px 0 0;

      button {
        text-align: left;
        background: none;
        border: none;
        border: 1px solid transparent;
        transition: 0.4s;
        &:focus,
        &:hover {
          border: 1px solid white;
          border-radius: 4px;
          outline: none;
          transition: 0.4s;
          padding: 8px 8px;
          transform: scale(0.98);
        }
      }

      &:active {
        transition: 0.1s;
        transform: scale(0.95);
      }

      @media screen and (max-width: 980px) {
        margin: 0 28px 0 0;
      }

      @media screen and (max-width: 944px) {
        margin: 0 0 32px;
      }

      @media screen and (max-width: 450px) {
        margin: 0 80px 32px 0;
      }

      &:last-child {
        margin: 0 0 0 0;
      }
    }
  }
}
