.navigation {
  display: flex;
  margin-top: 5px;

  @media (max-width: 990px) {
    &.hidden {
      & ul {
        display: none;
      }
    }
  }
  ul {
    @media (max-width: 990px) {
      position: absolute;
      top: 104px;
      right: 0;
      width: 100%;
      background-color: #ffff;
      padding: 52px 0;
      min-height: calc(10vh - 24px);
      height: fit-content;
      border-bottom: 1px solid #242b3a;

      li {
        line-height: 24px;
        text-align: left;
        padding-top: 26px;
        margin: 0 0 0 56px;

        &:first-child {
          padding-top: 0px;
        }
      }
    }

    @media (min-width: 990px) {
      display: flex;
      margin: 10px 0px 0px 90px;

      li {
        margin-right: 48px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      margin: 10px 0px 0px 200px;
    }
  }
}

.hamburger {
  position: absolute;
  top: 40px;
  right: 96px;
  cursor: pointer;
  user-select: none;

  @media (min-width: 990px) {
    display: none;
  }

  @media (max-width: 690px) {
    right: 40px;
  }
}
