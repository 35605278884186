.solutions_wrapper {
  border-top: 1px solid #242b3a;
}

.solutions {
  height: 628px;
  position: relative;

  background: url("../../../images/IndividualSolutions/Gradient.png") no-repeat,
    url("../../../images/IndividualSolutions/Tokyo.jpg") no-repeat;
  background-size: auto, auto;
  background-position: 100% 40%, 0px 72px;
  padding: 64px 0 0 0;

  @media screen and (min-width: 1300px) {
    background-position: 100% 40%, 40px 72px;
  }

  @media screen and (max-width: 1180px) {
    height: 1144px;
    background-position: -200px -300px, 20px 528px;
  }

  @media screen and (max-width: 827px) {
    background-position: 100% 100%, 0px 528px;
  }

  @media screen and (max-width: 450px) {
    height: 1144px;
    background-position: 100% 100%, 0px 695px;
    background-size: auto, 380px 380px;
  }

  @media screen and (min-width: 1366px) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 90px;
      background: linear-gradient(
        to right,
        white 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 2;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
      background: linear-gradient(
        to left,
        white 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    max-width: 1366px;
    margin: 0 auto;
  }

  &__container {
    margin: 0 0 0 80px;

    @media screen and (max-width: 450px) {
      margin: 0 0 0 28px;
    }
  }

  &__mainTitle {
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: #242b3a;
    margin: 0 0 16px;

    @media screen and (max-width: 724px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
    }
  }

  &__mainText {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #5b6273;
    margin: 0 0 56px;

    @media screen and (max-width: 724px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin: 0 20px 56px 0;
    }

    br {
      display: none;
      @media screen and (max-width: 1180px) {
        display: inline;
      }

      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 80px 0 0;

    @media screen and (max-width: 1180px) {
      flex-direction: column;
      padding: 0 80px 0 80px;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      padding: 0 80px 0 30px;
    }
  }

  &__item {
    width: 325px;
    height: 120px;

    @media screen and (max-width: 1180px) {
      width: 100%;
      height: 66px;
    }

    @media screen and (max-width: 827px) {
      width: 508px;
    }

    @media screen and (max-width: 550px) {
      width: 400px;
    }

    @media screen and (max-width: 450px) {
      width: 272px;
    }

    &:not(:first-child) {
      @media screen and (max-width: 1180px) {
        margin-top: 72px;
      }
    }
  }

  &__item:nth-child(3) {
    @media screen and (max-width: 450px) {
      margin-top: 120px;
    }
  }

  &__item:last-child {
    @media screen and (max-width: 1180px) {
      margin-top: 12px;
    }
  }

  &__item:nth-child(1),
  &__item:nth-child(2),
  &__item:nth-child(4) {
    margin-right: 72px;

    @media screen and (max-width: 1270px) {
      margin-right: 42px;
    }

    @media screen and (max-width: 1228px) {
      margin-right: 22px;
    }

    @media screen and (max-width: 1180px) {
      margin-right: 0;
    }
  }

  &_item:nth-child(3n + 1) {
    margin-right: 0;
  }

  &__item:nth-child(n + 4) {
    margin-top: 112px;

    @media screen and (max-width: 1180px) {
      margin-top: 72px;
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  &__icon {
    margin: 4px 11px 0 0;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #3b404b;
    margin: 0 0 16px;

    @media screen and (max-width: 724px) {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #5b6273;
    padding: 0 0 0 32px;

    @media screen and (max-width: 724px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
