.header {
  display: flex;
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(36, 43, 58, 1);
  padding: 26px 0 26px;

  button {
    background: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    border-bottom: 2px solid transparent;
  }

  @media screen and (min-width: 1366px) {
    justify-content: center;
    padding: 26px 80px 26px 0;
  }

  img {
    margin: 0 0 0 80px;

    @media screen and (max-width: 810px) {
      margin: 0 0 0 26px;
    }

    @media screen and (min-width: 1366px) {
      margin: 0 0 0 -160px;
    }
  }

  br {
    display: none;

    @media screen and (max-width: 420px) {
      display: inline;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
  }

  &__arrow {
    display: none;

    @media screen and (max-width: 990px) {
      display: inline;
      color: #5652f6;
      margin: 0 0 -2px 14px;
    }

    @media screen and (max-width: 420px) {
      display: none;
    }
  }

  &__link {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    color: black;
    transition: 0.4s;

    &:focus,
    &:hover {
      color: #5652f6;
      outline: none;
      transition: 0.4s;
    }

    &:active {
      transition: 0s;
      color: #abb7fa;
    }

    @media (max-width: 990px) {
      text-align: left;
      color: black;
      border-bottom: 2px solid transparent;
      padding-bottom: 2px;
      transition: 0.4s ease-in-out;

      &:hover {
        color: #5652f6;
        border-bottom-color: #5652f6;
        transition: 0.4s;
      }
    }
  }
}
