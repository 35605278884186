* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

div,
ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a,
button,
input,
textarea,
select {
  &:focus {
    outline: none;
  }
}

a,
button {
  cursor: pointer;
}

.container {
  width: 320px;
  margin: 0 auto;

  @media screen and (max-width: 355px) {
    width: 285px;
  }

  @media (min-width: 700px) {
    width: 600px;
  }

  @media (min-width: 990px) {
    width: 840px;
  }

  @media (min-width: 1200px) {
    width: 1050px;
  }

  @media (min-width: 1440px) {
    width: 1170px;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #ffff;
}

::-webkit-scrollbar-thumb {
  background: #abb7fa;
  border-radius: 8px;
}
